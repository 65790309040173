import * as React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import {
  Layout,
  NarrowContent,
  Heading,
  Section,
  Header,
  SEO,
} from "../components";

const IndexPage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("home.title")} />
      <NarrowContent>
        <Header />
        <Section>
          <Heading>{t("home.technical_support_header")}</Heading>
          <p>
            {t("home.technical_support_description")}{" "}
            <a href="mailto:support+app@kaikuhealth.com">
              support@kaikuhealth.com
            </a>
          </p>
        </Section>

        <Section>
          <Heading>{t("home.privacy_header")}</Heading>
          <Trans
            i18nKey="home.privacy_description"
            components={[
              <Link language="en" to="/global-privacy-policy/">
                global privacy policy
              </Link>,
              <Link language="en" to="/us-privacy-policy/">
                us privacy policy
              </Link>,
            ]}
          />
        </Section>

        <Section>
          <Heading>{t("home.kaiku_health_header")}</Heading>
          <Trans
            i18nKey="home.kaiku_health_description"
            components={[<a href="https://kaikuhealth.com">Kaiku Health</a>]}
          />
        </Section>
      </NarrowContent>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export function Head() {
  return (
    <meta name="google-site-verification" content="ZzP2V9h1qxRhSOIIdLmKQsAvLkSJ8bzQrNJ7Eixkp4I" />
  )
}

export default IndexPage;
